<template lang="pug">
v-card.section(
  @click="addToCart"
)
  v-badge.quantity(
    style="width: 100%"
    :value="item"
    color="success"
    left
    large
    overlap
  )
    template(#badge)
      strong {{ quantity }}
    v-layout(
      align-center
    )
      img.shrink(
        :src="`/parts/${part.img}`"
        contain
        height="60px"
        width="60px"
      )
      strong.ml-2.mb-0.grow {{ part.pn }}
      p.mb-0.mr-2 QTY {{ part.qty }}
</template>

<script>
export default {
  name: 'AdditionalItem',
  props: {
    part: {
      type: Object,
      required: false
    }
  },
  computed: {
    item () {
      const { OrderRow } = this.$FeathersVuex.api
      const { data: items } = OrderRow.findInStore({ query: { partNumber: this.part.pn, __isTemp: true }, temps: true })

      return items[0] || null
    },
    quantity () {
      return this.item ? this.item.qty : 0
    }
  },
  methods: {
    addToCart () {
      if (!this.part.pn) {
        return
      }

      const { OrderRow } = this.$FeathersVuex.api

      if (!this.quantity) {
        // eslint-disable-next-line no-unused-vars
        const row = new OrderRow({ qty: 1, partNumber: this.part.pn })
      } else if (this.quantity === 4) {
        this.item.remove()
      } else {
        this.item.qty++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  max-height: 60px;
  margin-bottom: 16px;
}

.product {
  height: 100%;
}
</style>
