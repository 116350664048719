import { render, staticRenderFns } from "./AdditionalItem.vue?vue&type=template&id=18826e80&scoped=true&lang=pug&"
import script from "./AdditionalItem.vue?vue&type=script&lang=js&"
export * from "./AdditionalItem.vue?vue&type=script&lang=js&"
import style0 from "./AdditionalItem.vue?vue&type=style&index=0&id=18826e80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18826e80",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VCard,VLayout})
